.container {
  border: 1px solid #e4e4e455;
  border-radius: 8px;
  padding: 0.75rem;
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    gap: 12px;

    .logo {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      overflow: hidden;
    }

    
  }

  .markets {
    height: 70px;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .market {
      height: 24;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .title {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .buttons {
        display: flex;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: auto;
  }
}