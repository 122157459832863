.container {
  .orders {
    display: flex;
    flex-direction: column;
    gap: 12px;



    .orderContainer {
      // height: 80px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-bottom: 1px solid #f1f2f6;
      gap: 8px;
      padding-bottom: 8px;

      .header {
        display: flex;
        gap: 12px;
        height: 40px;

        .image {
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }

        .question {
          font-weight: 700;
        }
      }

      .side {
        color: #fff;
        padding: 4px 8px;
        border-radius: 4px;
      }

      .price {
        font-weight: 700;
      }

      .prices {
        text-align: right;
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .content {
        flex: 1;
        padding: 0 4px;
        display: flex;
        flex-direction: column;

        .question {
          font-weight: bold;
          height: 34px;
        }

        .order {
          display: flex;
          align-items: center;
          gap: 12px;
          flex: 1;
    
          .column {
            width: 40px;
    
            &.size {
              flex: 1;
            }
    
            &.price {
              width: 70px;
            }
          }
    
          .action {
            margin-left: auto;
            width: 100px;
          }
        }
      }
    }
  }
}
