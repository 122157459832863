.container {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 8px;
  display: flex;
  flex-direction: column;

  .tags {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
    overflow: auto;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
  }

  .tag {
    height: 32px;
    align-items: center;
    display: flex;

    &:not(.active) {
      background-color: #fff;
    }
  }
}