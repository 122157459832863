.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // height: 100%;

  .header {
    display: flex;
    align-items: center;
    gap: 12px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .image {
      width: 50px;
      height: 50px;
    }

    .closeButton {
      margin-left: auto;
    }
  }

  .title {
    font-weight: bold;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    font-weight: bold;

    .property {
      font-weight: bold;
    }
  }

  .sideButton {
    &.sell {
      flex: 1;
      &.active {
        background-color: #E64800;
        border-color:#E64800;
      }

      &:hover {
        &:not(.active) {
          color: #000;
        }
      }
  
      &::before {
        background-color: transparent;
      }
    }

    &.buy {
      flex: 1;
      &.active {
        background-color: #27AE66;
        border-color:#27AE66;
      }

      &:hover {
        &:not(.active) {
          color: #000;
        }
      }
  
      &::before {
        background-color: transparent;
      }
    }
  }
}