.container {
  top: 0;
  width: 100%;
  position: sticky;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 8px 20px 0px 20px;
  gap: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .iconsContainer {
    display: flex;
    height: 40px;

    .iconContainer {
      flex: 1;
      width: 50px;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-size: 10px;
    }
  }

  .top {
    height: 50%;
    display: flex;  
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .balance {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
    }
  }

  .categories {
    height: 50%;
    display: flex;  
    align-items: center;
    gap: 12px;
    overflow: auto;

    .category {
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 8px;

      &.active {
        font-weight: bold;
        .badge {
          display: block;
        }
      }

      .badge {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: #000000;
        border-radius: 2px;
        display: none;
      }
    }
  }

  .bottom {
    

    .buttons {
      display: flex;
      margin-left: auto;
      gap: 8px;
    }
  }
}