.button {
  font-weight: bold;
  width: 100%;

  &.success {
    background-color: #27AE601A;
    color: #2EAE60;

    &:hover {
      background-color: #27AE60 !important;
      color: #fff !important;
      border-color:#27AE60 !important;
    }
  }

  &.danger {
    background-color: #EB57571A;
    color: #E64800;

    &:hover {
      background-color: #E64800 !important;
      color: #fff !important;
      border-color: #E64800 !important;
    }
  }
}

