.depositForm {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  gap: 8px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
}